import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { getStoredCity, log } from '@ecommerce/shared'
import { PgPageProps } from '../../types/PgPages'

const onError = () => navigate('/')

const slugRedirect = (slug: string) => (props: PgPageProps) => {
  useEffect(() => {
    try {
      log.trace('REDIRECT')
      const { search: query } = props.location
      const city = getStoredCity()
      if (!city) throw new Error('City not found in storage')
      navigate(`/${city.slug}/${slug}?${query}`)
    } catch (e) {
      log.error(e, e.message)
      onError()
    }
  }, [])
  return <div />
}

const dynamicSlugRedirect = (slug: string, dynamicSlugParamKey: string) => (props: PgPageProps) => {
  useEffect(() => {
    try {
      log.trace('REDIRECT')
      const params = props.location && props.location.search ? new URLSearchParams(props.location.search) : null
      if (!params) throw new Error('No search params found')
      const city = getStoredCity()
      if (!city) throw new Error('City not found in storage')
      const dynamicSlug = params ? params.get(dynamicSlugParamKey) : null
      if (!slug) throw new Error('Slug not found in search params')
      let query = ''
      params.forEach((value, key) => {
        if (key !== dynamicSlugParamKey) query = `${query}&${key}=${value}`
      })
      navigate(`/${city.slug}/${slug}/${dynamicSlug}?${query}`)
    } catch (e) {
      log.error(e, e.message)
      onError()
    }
  }, [])
  return <div />
}

export { slugRedirect, dynamicSlugRedirect }
